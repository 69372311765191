<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav" v-if="roles.includes('g')">
      <li class="nav-item">
              <sidenav-item
                :to="{ name: 'Dashboard' }"
                text="Dashboard"
              @click="collapse = !collapse"
              >
          <template #icon>
            <i class="fa fa-home text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>
          </li>
              <sidenav-item
              @click="collapse = !collapse"
                :to="{ name: 'Organigramme' }"
                text="Mon Entreprise"
              >
          <template #icon>
            <i class="fa fa-shop text-info text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>


              <sidenav-item v-if="rights.includes('immeubles')"
              @click="collapse = !collapse"
                :to="{ name: 'Liste des immeubles' }"
                text="Mes Immeubles"
              >
          <template #icon>
            <i class="fa fa-building text-danger text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>
              <sidenav-item v-else
                text="Mes Immeubles"
              >
          <template #icon>
            <i class="fa fa-building text-danger text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>

            <sidenav-item v-if="rights.includes('baux')"
              @click="collapse = !collapse"
                :to="{ name: 'Liste des baux' }"
                text="Mes Baux"
              >
          <template #icon>
            <i class="fa fa-file-contract text-orange text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>
            <!--
              <sidenav-item v-else
                text="Mes Baux"
              >
          <template #icon>
            <i class="fa fa-file-contract text-orange text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>-->

              <sidenav-item v-if="rights.includes('résidents')"
              @click="collapse = !collapse"
                :to="{ name: 'Liste des résidents' }"
                text="Mes Résidents"
              >
          <template #icon>
            <i class="fa fa-users text-yellow text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>
              <sidenav-item v-else
                text="Mes Résidents"
              >
          <template #icon>
            <i class="fa fa-users text-yellow text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>



              <sidenav-item v-if="rights.includes('fournisseurs')"
              @click="collapse = !collapse"
                :to="{ name: 'Liste des fournisseurs' }"
                text="Mes Fournisseurs"
              >
          <template #icon>
            <i class="fa fa-wrench text-purple text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>





            <sidenav-item
            v-if="rights.includes('calendrier')"
              @click="collapse = !collapse"
                :to="{ name: 'Calendrier' }"
                text="Mon Calendrier"
              >
          <template #icon>
            <i class="fa fa-calendar text-warning text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>
            <sidenav-item
            v-else
                text="Mon Calendrier"
              >
          <template #icon>
            <i class="fa fa-calendar text-warning text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>


            <sidenav-item
            v-if="rights.includes('mails')"
              @click="collapse = !collapse"
                :to="{ name: 'Liste des mails' }"
                text="Mes E-Mails"
              >
          <template #icon>
            <i class="fa fa-envelope text-green text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>
            <sidenav-item
            v-else
                text="Mes E-Mails"
              >
          <template #icon>
            <i class="fa fa-envelope text-green text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>





            <sidenav-item
            v-if="rights.includes('courrier')"
              @click="collapse = !collapse"
                :to="{ name: 'Liste des courriers' }"
                text="Mon Courrier"
              >
          <template #icon>
            <i class="fa fa-archive text-pink text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>

            <sidenav-item
            v-if="rights.includes('appels')"
              @click="collapse = !collapse"
                :to="{ name: 'Liste des appels' }"
                text="Mes Appels"
              >
          <template #icon>
            <i class="fa fa-phone text-teal text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>

            <sidenav-item
            v-else
                text="Mes Appels"
              >
          <template #icon>
            <i class="fa fa-phone text-teal text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>

            <sidenav-item
            v-if="rights.includes('feedbacks')"
              @click="collapse = !collapse"
                :to="{ name: 'Liste des feedbacks' }"
                text="Mes Feedbacks"
              >
          <template #icon>
            <i class="fa fa-bullhorn text-primary text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>

            <sidenav-item
            v-if="rights.includes('évènements')"
              @click="collapse = !collapse"
                :to="{ name: 'Liste des évènements' }"
                text="Mes Évènements"
              >
          <template #icon>
            <i class="fa fa-tasks text-primary text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>

            <sidenav-item
            v-if="rights.includes('discussions')"
              @click="collapse = !collapse"
                :to="{ name: 'Liste des discussions' }"
                text="Mes Discussions"
              >
          <template #icon>
            <i class="fa fa-comments text-info text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>



            <sidenav-item
            v-if="rights.includes('assemblées')"
              @click="collapse = !collapse"
                :to="{ name: 'Liste des assemblées' }"
                text="Mes Assemblées"
              >
          <template #icon>
            <i class="fa fa-quote-left text-brown text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>

            <sidenav-item
            v-if="rights.includes('dropzones')"
              @click="collapse = !collapse"
                :to="{ name: 'Liste des dropzones' }"
                text="Ma Dropzone"
              >
          <template #icon>
            <i class="fa fa-file text-red text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>

            <sidenav-item
            v-if="rights.includes('intranet')"
              @click="collapse = !collapse"
                :to="{ name: 'Liste Intranet' }"
                text="Mon Intranet"
              >
          <template #icon>
            <i class="fa fa-folder text-gray text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>
            <sidenav-item
            v-else
                text="Mon Intranet"
              >
          <template #icon>
            <i class="fa fa-folder text-gray text-center text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>

            <li class="nav-item"
            style="cursor: pointer;"
              @click="toggleConfigurator">
              <div class="nav-link">
              <i class="fa fa-cog text-sm text-center icon-moved"></i>
              <span class="sidenav-normal"> Réglages </span>

              </div>
            </li>

            <!--
      <li class="mt-3 nav-item">
        <hr class="mt-0 horizontal dark" />
        <h6
          class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder opacity-6"
          :class="$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          DOCU
        </h6>
      </li>-->
    </ul>
    <ul class="navbar-nav" v-if="roles.includes('a')">
      <li class="nav-item">
        <sidenav-item
          :to="{ name: 'Dashboard' }"
          text="Dashboard"
        >
          <template #icon>
            <i class="fa fa-home text-dark text-sm opacity-10 icon-moved"></i>
          </template>
        </sidenav-item>
      </li>
      <br/>
          <li class="nav-item">
            <sidenav-item
              :to="{ name: 'Liste des supersyndics' }"
              text="Syndics"
            >
        <template #icon>
          <i class="fa fa-building text-danger text-sm opacity-10 icon-moved"></i>
        </template>
          </sidenav-item>
        </li>
          <li class="nav-item">
            <sidenav-item
              :to="{ name: 'Liste des agences' }"
              text="Agences"
            >
        <template #icon>
          <i class="fa fa-shop text-warning text-sm opacity-10 icon-moved"></i>
        </template>
          </sidenav-item>
        </li>
          <li class="nav-item">
            <sidenav-item
              :to="{ name: 'Liste des collaborateurs' }"
              text="Collaborateurs"
            >
        <template #icon>
          <i class="fa fa-users text-yellow text-sm opacity-10 icon-moved"></i>
        </template>
          </sidenav-item>
        </li>
        <br/>

        <li class="nav-item">
            <sidenav-item
              :to="{ name: 'Liste des modèles' }"
              text="Modèles"
            >
        <template #icon>
          <i class="fa fa-table text-info text-sm opacity-10 icon-moved"></i>
        </template>
          </sidenav-item>
        </li>

          <li class="nav-item">
            <sidenav-item
              :to="{ name: 'Liste des vues' }"
              text="Vues"
            >
        <template #icon>
          <i class="fa fa-eye text-success text-sm opacity-10 icon-moved"></i>
        </template>
          </sidenav-item>
        </li>

        <li class="nav-item">
            <sidenav-item
              :to="{ name: 'Liste des controleurs' }"
              text="Contrôleurs"
            >
        <template #icon>
          <i class="fa fa-gamepad text-red text-sm opacity-10 icon-moved"></i>
        </template>
          </sidenav-item>
        </li>

    </ul>
    <ul class="navbar-nav" v-if="roles.includes('e')">
      <li class="nav-item">
        <sidenav-item
          :to="{ name: 'Dashboard' }"
          text="Dashboard"
        >
          <template #icon>
            <i class="fa fa-home text-dark text-sm opacity-10 icon-moved"></i>
          </template>
        </sidenav-item>
      </li>
      <br/>
          <li class="nav-item">
            <sidenav-item
              :to="{ name: 'Liste des agences' }"
              text="Mon Entreprise"
            >
        <template #icon>
          <i class="fa fa-shop text-warning text-sm opacity-10 icon-moved"></i>
        </template>
          </sidenav-item>
        </li>
          <li class="nav-item">
            <sidenav-item
              :to="{ name: 'Liste des agences' }"
              text="Liste des collaborateurs"
            >
        <template #icon>
          <i class="fa fa-users text-yellow text-sm opacity-10 icon-moved"></i>
        </template>
          </sidenav-item>
        </li>
        <br/>
          <li class="nav-item">
            <sidenav-item
              :to="{ name: 'Liste des évènements' }"
              text="Mes Évènements"
            >
        <template #icon>
          <i class="fa fa-calendar text-primary text-sm opacity-10 icon-moved"></i>
        </template>
          </sidenav-item>
        </li>


    </ul>
    <ul class="navbar-nav"  v-else-if="roles.length == 0">
      <li class="nav-item">
              <sidenav-item
                :to="{ name: 'Dashboard' }"
                text="Dashboard"
              >
          <template #icon>
            <i class="fa fa-home text-dark text-sm opacity-10 icon-moved"></i>
          </template>
            </sidenav-item>
      </li>

    </ul>



  </div>
  <!--
  <div class="mt-3 sidenav-footer">
    <sidenav-card
      :card="{
        title: 'Besoin d\'aide ?',
        description: 'Please check our docs',
        links: [
          {
            label: 'Documentation',
            route:
              'https://www.creative-tim.com/learning-lab/vue/overview/argon-dashboard/',
            color: 'dark'
          },
        ]
      }"
    />
  </div>-->
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
//import SidenavCard from "./SidenavCard.vue";
import { mapMutations,mapState } from "vuex";
export default {
  name: "SidenavList",
  components: {
    SidenavItem
    //SidenavCard
  },
  data() {
    return {
      roles: [],
      rights: [],
      collapse: false,
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.profile.me
    }),
  },
  props:{
    modelValue: Boolean
  },

  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
  watch: {

    modelValue: {
      handler: function (val) {
        this.collapse = val;
      },
      immediate: true,
    },
    collapse: {
      handler: function (val) {
        this.$emit("update:modelValue", val);
      },
      immediate: true,
    },
    me: {
      handler: function (val) {
        this.user = val;
        this.roles = val.roles.map((r) => r);
        if(val.info.access!=null){
        let tmp_rights = val.info.access.map((r) => r);
        var vtmp;
        if(tmp_rights.length >0 ){
          tmp_rights.forEach((r) => {
            vtmp = JSON.parse(r.acces_text)
            vtmp.forEach((r) => {
              if(!this.rights.includes(r))
                this.rights.push(r)
            })
          })
        } else {
          //this.rights = ["immeubles","résidents","fournisseurs","calendrier","mails","discussions","courriers","appels","évènements","assemblées","dropzones","intranet"]
          this.rights = ["immeubles","résidents","fournisseurs","mails","évènements","appels","feedbacks"]

        }}else {
          //this.rights = ["immeubles","résidents","fournisseurs","calendrier","mails","discussions","courriers","appels","évènements","assemblées","dropzones","intranet"]
          this.rights = ["immeubles","résidents","fournisseurs","mails","évènements","appels","feedbacks"]

        }

      },
      deep: true,
    },
  },
    created(){
      if(this.me)
          this.roles = this.me.roles.map((r) => r);
        //this.rights = this.me.info.access.map((r) => r);
      let val = this.me;
      if(val){
        if(val.info.access!=null){
        let tmp_rights = val.info.access.map((r) => r);
        var vtmp;
        if(tmp_rights.length >0 ){
          tmp_rights.forEach((r) => {
            vtmp = JSON.parse(r.acces_text)
            vtmp.forEach((r) => {
              if(!this.rights.includes(r))
                this.rights.push(r)
            })
          })
        } else {
          //this.rights = ["immeubles","résidents","fournisseurs","calendrier","mails","discussions","courriers","appels","évènements","assemblées","dropzones","intranet"]
          this.rights = ["immeubles","résidents","fournisseurs","mails","évènements","appels", "feedbacks"]

        }}else {
          //this.rights = ["immeubles","résidents","fournisseurs","calendrier","mails","discussions","courriers","appels","évènements","assemblées","dropzones","intranet"]
          this.rights = ["immeubles","résidents","fournisseurs","mails","évènements","appels", "feedbacks"]

        }}
    },
};
</script>
<style>
.g-sidenav-hidden .navbar-collapse .icon-moved {
  padding-left: 15px;
}

.g-sidenav-hidden .navbar-collapse .sidenav-normal {
  padding-left: 10px;
}

</style>
