<template>
  <Transition>
  <Loading v-if="mainLoading"/>
  <div v-else>
    <div class="py-1 container-fluid">
    <Modal v-model="modals.equipe.show" closeable header="Équipe de gestion">
      <table class="table">
        <tr v-for="c in item.collaborateurs" :key="c.id">
          <td>{{ c.prenom }} {{ c.nom }}</td>
          <td v-if="c.user">{{ c.user.email }}</td>
          <td>{{ c.role }}</td>
          <td v-if="c.user"><a v-for="r in c.roles" :key="r.id">{{ r.fonction.nom }} {{ r.service.nom }} </a></td>
        </tr>
      </table>
    </Modal>



    <base-header :class="'bg-gradient-yellow'" class="pb-1 border-radius-xl">
      <div class="row align-items-center pt-3 pb-1">
        <div class="col-lg-3 text-left">
          <button class="btn btn-white mt-2 btn-xs" @click="$router.go(-1)">
            <span class="btn-inner--icon"><i class="fas fa-arrow-left"></i></span> Retour
          </button>
        </div>
        <div class="col-lg-6 text-center">
          <h6 class="h5  d-inline-block mb-0"> {{ item.civilite }} &nbsp;</h6>
          <h6 class="h3  d-inline-block mb-0">{{ item.nom }} {{ item.prenom }}

          </h6>
          <h6 class="h5  d-inline-block mb-0">&nbsp; {{ item.EXTAPI_PERSO_ID }} </h6>
        </div>
        <div class="col-lg-3 text-end">
          <button class="btn btn-white mt-2 btn-xs" @click="modals.equipe.show = true">
            <span class="btn-inner--icon"><i class="fas fa-users"></i></span> Équipe de gestion
          </button>

        </div>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-4">

              <mini-statistics-card class="" @click="modals.residents.show = true"
                :title="{ text: 'Téléphone 1', color: 'opacity-7 text-dark' }"
                :value="{ text: item.phones[0]?item.phones[0].phone:'', color: 'text-dark' }" :icon="{
                  component: 'fa fa-users text-white',
                  background: 'bg-warning',
                  shape: 'rounded-circle',
                }" />
            </div>
            <div class="col-lg-4">

              <mini-statistics-card
                :title="{ text: 'Satisfaction', color: 'opacity-7 text-dark' }"
                :value="{ text: '?%', color: 'text-dark' }" :icon="{
                  component: 'fas fa-smile text-white',
                  background: 'bg-info',
                  shape: 'rounded-circle',
                }" />
            </div>
            <div class="col-lg-4">

              <mini-statistics-card  :title="{ text: 'Évènements', color: 'opacity-7 text-dark' }"
                :value="{ text: item.events, color: 'text-dark' }" :icon="{
                  component: 'fas fa-calendar text-white',
                  background: 'bg-primary',
                  shape: 'rounded-circle',
                }" />
            </div>

          </div>


        </div>
        <div class="col-lg-4">

          <mini-statistics-card @click="openAdresse()" class="bg-white"
            :title="{ text: 'Adresse', color: 'opacity-7 text-dark' }"
            :value="{ text: item.adresse1 + ' ' + item.adresse2 + ' ' + item.cp + ' ' + item.ville, color: 'text-dark' }"
            :icon="{
              component: 'fas fa-location-arrow text-white',
              background: 'bg-success',
              shape: 'rounded-circle',
            }" />

        </div>





      </div>
    </base-header>

  <div class="py-1 h-100">
    <div class="row">
      <div class="col-lg-3  ">
        <div class="card p-3" style="height: calc(100vh - 300px);" >

          <div class="btn-group" role="group" aria-label="Basic example">
            <base-button size="xs" outline class="bg-gradient-warning active"
              >Immeubles et Lots</base-button>
          </div>
            <div  style="overflow-y: scroll;">

                <div v-if="imm_links[0]">
                  <h6>En tant que copropriétaire</h6>
                    <el-tree
                      ref="treeRef"
                      class="filter-tree"
                      :data="imm_links"
                      :props="{
                    children: 'children',
                    label: 'nom',
                  }"
                      default-expand-all
                    >
                                <template #default="{  data }" >
                                  <div v-if="data.hasChildren && data.immeuble_full" @click="goImm(data.immeuble_full.id)">
                                    {{ data.num_imm }} - <a v-if="data.immeuble_full.nom.trim() != ''">{{ data.immeuble_full.nom }}</a>
                                    <a v-else>{{ data.immeuble_full.adresse1 }} {{ data.immeuble_full.ville }}</a>

                                  </div>
                                  <div v-else-if="data.immeuble && data.typedescr" @click="goLot(data.id)">
                                    {{ data.EXTAPI_LOT_ID }} -
                                    {{ data.typedescr.texte }}
                                    - Étage {{ data.etage }}

                                  </div>
                                  <div v-else>{{ data }}</div>
                                </template>
                    </el-tree>
                </div>
                <br  v-if="imm_baux[0] && imm_links[0]">
                  <div v-if="imm_baux[0]">
                    <h5 >En tant que locataire</h5>
                    <el-tree
                      ref="treeRef"
                      class="filter-tree"
                      :data="imm_baux"
                      :props="{
                    children: 'children',
                    label: 'nom',
                  }"
                      default-expand-all
                    >
                                <template #default="{  data }" >
                                  <div v-if="data.hasChildren" @click="goImm(data.id)">

                                    {{ data.EXTAPI_IMM_ID }} - <a v-if="data.nom.trim() != ''">{{ data.nom }}</a>
                                    <a v-else>{{ data.adresse1 }} {{ data.ville }}</a>

                                  </div>
                                  <div v-else @click="goLot(data.id)">
                                    {{ data.EXTAPI_LOT_ID }} -
                                    {{ data.typedescr.texte }}
                                    - Étage {{ data.etage }}

                                  </div>
                                    <span class="badge badge-danger" v-if="data.bail" @click="goBail(data.bail)"> Bail{{ data.bail }}</span>
                                </template>
                    </el-tree>
                  </div>
</div>
        </div>

      </div>
      <div class="col-lg-6">
        <div class="card p-3" style="height: calc(100vh - 300px);">
          <div style="display: flex;">
            <div class="btn-group" style="width:100%" role="group" aria-label="Basic example">
              <base-button size="xs" type="primary" outline :class="evenements_type == 'actuels' ? 'active' : ''"
                @click="evenements_type = 'actuels'">Évènements actuels</base-button>
              <base-button size="xs" type="primary" outline :class="evenements_type == 'futurs' ? 'active' : ''"
                @click="evenements_type = 'futurs'">Évènements futurs</base-button>
            </div>
          </div>
          <div v-if="evenements_type == 'actuels'" style="overflow-y: scroll;">
            <div class="text-center">
              <base-button v-if="rights.includes('appels')" size="xs" type="neutral" @click="newCallResident"
              style="border : 2px solid #21c997; "
              ><i class="fa fa-plus"></i>Ajouter un appel</base-button>
                &nbsp;
              <base-button
              style="border : 2px solid #5e72e4; "
              v-if="rights.includes('évènements')" size="xs" type="neutral" @click="newEventResident"><i class="fa fa-plus"></i>Ajouter un évènement</base-button>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card p-3" style="height: calc(100vh - 300px);">
          <div style="display: flex;">
            <div class="btn-group" style="width:100%" role="group" aria-label="Basic example">
              <base-button size="xs" type="purple" outline
                :class="attributs_or_contrats == 'attributs' ? 'active' : ''"
                @click="attributs_or_contrats = 'attributs'">Attributs</base-button>
              <!--<base-button size="sm" type="purple" outline :class="attributs_or_contrats == 'contrats' ? 'active' : ''"
                @click="attributs_or_contrats = 'contrats'">Contrats</base-button>-->
            </div>
          </div>
          <div v-if="attributs_or_contrats == 'attributs'" style="overflow-y: scroll;">
            <!--<base-button size="sm" type="neutral" @click="modals.attributs.show = true">
              <i class="fas fa-plus"></i>Ajouter</base-button>-->
              <b v-if="item.phones.length >0">Téléphones</b>
              <table class="table-responsive align-items-center table-flush">
                <tr v-for="phone in item.phones" :key="phone.id">
                <td style="width:100%; font-size:10pt;">{{ phone.phone }}</td>
                <td class="text-end"><a class="btn btn-xs btn-cyan btn-round mb-0" :href="'tel:'+phone.phone"><i class="fa fa-phone"></i></a> </td>
                </tr>
              </table>
              <b>Mails</b>
              <table class="table-responsive align-items-center table-flush">
                <tr v-for="mail in item.mails" :key="mail.id">
                  <td style="width:100%; font-size:10pt;"><a >{{ mail.mail }}</a></td>
                <td >

                  <a class="btn btn-xs btn-round btn-success mb-0" :href="'mailto:'+mail.mail"><i class="fa fa-paper-plane"></i></a>
                </td>
                </tr>
              </table>

              <b>Mandats de gestion</b>
                    <el-tree
                      ref="treeRef"
                      class="filter-tree"
                      :data="item.mandats_orga"
                      :props="{
                    children: 'children',
                    label: 'nom',
                  }"
                    >
                                <template #default="{  data }" >
                                  <div v-if="data.hasChildren">
                                    <a >N° immeuble : {{ data.num_imm }}</a>


                                  </div>
                                  <div v-else class="text-xs">
                                        <a >N° mandat : {{ data.num_mandat }}</a><br/>
                                        <a >N° au registre : {{ data.mandat_ref }}</a><br/>
                                        <a v-if="data.date_effet"> Date d'effet : {{date_convert(data.date_effet) }}<br/></a>
                                        <a v-if="data.date_fin_effet"> Date de fin d'effet : {{date_convert(data.date_fin_effet) }}<br/></a>
                                        <a v-if="data.motif_fin"> Motif fin d'effet :
                                            <a v-if="data.motif_fin == 'F'">Fin Mandat</a>
                                            <a v-else-if="data.motif_fin == 'T'">Transfert</a>
                                            <a v-else-if="data.motif_fin == 'D'">Décès</a>
                                            <a v-else-if="data.motif_fin == 'V'">Vente</a>
                                            <a v-else-if="data.motif_fin == 'R'">Résiliation</a>
                                            <a v-else>{{ data.motif_fin }}</a>

                                            <br/></a>

                                        <a v-if="parseInt(data.duree_mandat_annees) >0 || parseInt(data.duree_mandat_mois) >0" >
                                            Durée du mandat :
                                            <a v-if="data.duree_mandat_annees >1">{{data.duree_mandat_annees }} ans</a>
                                            <a v-else-if="data.duree_mandat_annees >0">{{data.duree_mandat_annees }} an et</a>

                                            <a v-if="data.duree_mandat_annees >0 && data.duree_mandat_mois >0"> et </a>

                                             <a v-if="data.duree_mandat_mois >0">{{data.duree_mandat_mois }} mois</a>
                                             <br/>
                                        </a>
                                        <a> Taux honoraires : {{ data.taux_honoraires }}%</a>

                                  </div>
                                </template>
                    </el-tree>


              <!--<table class="table-responsive align-items-center table-flush">
                <tr v-for="mandata in item.mandats.filter(m => m.num_imm != '0000')" :key="mandata.id">
                  <td style="width:100%; font-size:10pt;">
                    <a >Num mandat : {{ mandata.num_mandat }}</a><br/>
                    <a >Num au registre : {{ mandata.mandat_ref }}</a><br/>
                    <a> Date d'effet : {{date_convert(mandata.date_effet) }}</a><br/>
                    <a> Durée du mandat : {{mandata.duree_mandat_annees }} an(s) et {{ duree_mandat_mois }} mois</a><br/>
                    <a> Taux honoraires : {{ mandata.taux_honoraires }}%</a>

                  </td>
                </tr>
              </table>-->

          </div>
          <div v-if="attributs_or_contrats == 'contrats'" style="overflow-y: scroll;">
            <ArgonInput v-model="searchcontrat" size="small" placeholder="Rechercher un contrat..." />
          </div>

        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
</Transition>
</template>

<script>
import ArgonInput from '../../../components/ArgonInput.vue';
//import ArgonButton from '../../../components/ArgonButton.vue';
import formMixin from "../../../mixins/form-mixin.js"
//import ValidationError from "../../../components/ValidationError.vue";
import showSwal from '../../../mixins/showSwal.js';
import MiniStatisticsCard from '../../../components/Cards/MiniStatisticsCard.vue';
import BaseHeader from "../../../components/BaseHeader.vue"
import BaseButton from "@/components/BaseButton.vue"
import setNavPills from "@/assets/js/nav-pills.js";
import {ElTree} from "element-plus"
import Modal from '@/components/Modal.vue'

import Loading from '@/components/Loading.vue'
export default {
  name: "EditRolePage",
  components: {
    ArgonInput,ElTree,
    //ArgonButton,
    //ValidationError,
    MiniStatisticsCard, BaseHeader, BaseButton, Modal,Loading
  },
  mixins: [formMixin, showSwal],
  data() {
    return {
      mainLoading:true,
      rights: [],
      modals: {
        equipe: {
          show: false,
          item: {}
        },
        contrats: {
          show: false,
          item: {}
        },
        residents: {
          show: false,
          f: []
        },
        locataires: {
          show: false,
          f: []
        },
        edit_attributs: {
          show: false,
          id: this.$route.params.id,
          f: [],
          name: '',
          value: '',
          fichiers: '',
          attr_type: '',
          codes: []
        },
        attributs: {
          show: false,
          id: this.$route.params.id,
          f: [],
          codes: []
        }
      },
      searchcop: '',
      residents: 'all',
      resident_search_placeholder: 'Rechercher un résident',
      evenements_type: "actuels",
      cs_or_mandats: "cs",
      attributs_or_contrats: 'attributs',
      searchcontrat: '',
      item: {
        civilite:'',
        attributes: []
      },
      options: {
        sort: "created_at",
        query: "",
        nr: "1",
        perpage: "5"
      }
    }
  },
  computed: {

    filterCops() {
      return this.cops.filter(
        (data) =>
          (!this.searchcop && this.residents == 'all') ||
          (!this.searchcop && this.residents == 'cop' && data.qualite === undefined) ||
          (!this.searchcop && this.residents == 'loc' && data.qualite) ||
          (data.perso_mini.nom.toLowerCase().includes(this.searchcop.toLowerCase()) && this.residents === 'all')
          ||
          (data.perso_mini.nom.toLowerCase().includes(this.searchcop.toLowerCase()) && data.qualite === undefined && this.residents === 'cop')
          ||
          (data.perso_mini.nom.toLowerCase().includes(this.searchcop.toLowerCase()) && data.qualite !== undefined && this.residents === 'loc')
      ).sort(function (a, b) {
        if (a.perso_mini.nom < b.perso_mini.nom) {
          return -1;
        }
        if (a.perso_mini.nom > b.perso_mini.nom) {
          return 1;
        }
        return 0;
      });
      //el => el.perso_mini.nom)

    },
  },
  async mounted() {
    setNavPills();

    let val = await this.$store.getters["profile/me"];
    this.roles = val.roles.map((r) => r);
    if(val.info.access !== null){
        let tmp_rights = val.info.access.map((r) => r);
        var vtmp;
        if(tmp_rights.length >0 ){
          tmp_rights.forEach((r) => {
            vtmp = JSON.parse(r.acces_text)
            vtmp.forEach((r) => {
              if(!this.rights.includes(r))
                this.rights.push(r)
            })
          })
        } else {
          //this.rights = ["immeubles","résidents","fournisseurs","calendrier","mails","discussions","courriers","appels","évènements","assemblées","dropzones","intranet"]
          this.rights = ["immeubles","résidents","fournisseurs","mails","évènements"]

        }
      }



    await this.$store.dispatch("perso/getPerso", this.$route.params.id);
    this.item = await this.$store.getters["perso/onePerso"];

          this.lots = this.item.lots;

          this.item.collaborateurs = Array();
          this.item.mandats.forEach(mandat => {
            // not in array
            if (mandat.resp !== null && mandat.resp !== undefined && this.item.collaborateurs.findIndex( elt => elt.id === mandat.resp.id ) === -1){
              mandat.resp.role = 'Responsable Location';
            this.item.collaborateurs.push(mandat.resp)

            }
            if (mandat.assistante !== null && mandat.assistante !== undefined && this.item.collaborateurs.findIndex( elt => elt.id === mandat.assistante.id ) === -1){
              mandat.assistante.role = 'Assistante Location';
              this.item.collaborateurs.push(mandat.assistante)

            }
            if (mandat.comptable !== null && mandat.comptable !== undefined && this.item.collaborateurs.findIndex( elt => elt.id === mandat.comptable.id ) === -1){
              mandat.comptable.role = 'Comptable Location';
              this.item.collaborateurs.push(mandat.comptable)

            }
          });

          this.item.mandats_orga = [];
            this.item.mandats.filter(m => m.num_imm != '0000').sort(
                (a, b) => a.num_imm - b.num_imm
            ).forEach(
                (mandat) => {
                    // add mandat in mandats_orga based on mandat_ref
                    let pres = this.item.mandats_orga.findIndex( elt => elt.num_imm === mandat.num_imm );
                    if( pres === -1 ){
                        this.item.mandats_orga.push(
                            {
                                'hasChildren': true,
                                'num_imm': mandat.num_imm,
                                'children': [mandat]
                            }
                        )
                    } else {
                        this.item.mandats_orga[pres].children.push(mandat)

                    }
                }
            )



          this.imm_links = Array();
          var i =0;
          this.item.imm_links.forEach(imm => {
            this.lots.forEach(lot => {
              let pres1 = imm.num_imm === lot.immeuble.EXTAPI_IMM_ID;

              let pres = this.imm_links.findIndex( elt => elt.num_imm === lot.immeuble.EXTAPI_IMM_ID );
              if(pres1 && pres === -1 ){
                this.imm_links.push(imm)
                this.imm_links[i].hasChildren = true;
                this.imm_links[i].children = [lot];
                i+=1;


              } else if(pres1) {
                this.imm_links[pres].children.push(lot);

              }

            });
          })

          console.log('itis', this.item, this.imm_links);

          this.imm_baux = Array();
          i =0;
          this.item.imm_links.forEach(el=>{
            if(el.bail_actif !== null && el.bail_actif !== undefined){

            el.bail_actif.lots.forEach(lot => {

              let pres = this.imm_baux.findIndex( elt => elt.EXTAPI_IMM_ID === lot.immeuble.EXTAPI_IMM_ID );
              if( pres === -1 ){
                this.imm_baux.push(lot.immeuble)
                this.imm_baux[i].hasChildren = true;
                this.imm_baux[i].bail = el.bail_actif.id;
                this.imm_baux[i].children = [lot];
                i+=1;


              } else {
                this.imm_baux[pres].children.push(lot);

              }

            });
            }
        }
          )

          this.mainLoading = false

  },
  methods: {

    date_convert(date){
        return new Date(date).toLocaleDateString('fr-FR')
      },
    openAdresse() {
      window.open('https://www.google.com/maps/search/?api=1&query=' + this.item.adresse1 + ' ' + this.item.adresse2 + ' ' + this.item.cp + ' ' + this.item.ville, '_blank');

    },
    goLot(id){
      this.$router.push({
        name: "Lot",
        params: { id: id },
      });
    },

    newEventResident(){
      console.log(this.item)
      this.$router.push({
        name: "Liste des évènements",
        params:{newEventProp: JSON.stringify({'perso_id':this.item.id,'perso':this.item})}
      })
    },
    newCallResident(){
      this.$router.push({
        name: "Liste des appels",
        params:{newCallProp: JSON.stringify({'perso_id':this.item.id,'perso':this.item})}
      })
    },
    showChildren(row) {
      console.log(row);
      this.item.attributes.forEach(element => {
        if (element.id == row.id) {
          console.log(element);
          element.show_children = !element.show_children;
        }
      });
      // simplifier
      this.item.attributes.push({})
      this.item.attributes.pop()
    },
    showAllResidents() {
      this.residents = 'all';
      this.resident_search_placeholder = "Rechercher un résident";

    },
    showOnlyCops() {
      this.residents = 'cop';
      this.resident_search_placeholder = "Rechercher un copropriétaire";

    },
    showOnlyLocs() {
      this.residents = 'loc';
      this.resident_search_placeholder = "Rechercher un locataire";

    },
        goImm(id){
        this.$router.push({
          name: "Immeuble",
          params: { id: id },
        });

        },
        goBail(id){
        this.$router.push({
          name: "Bail",
          params: { id: id },
        });

        },
    changeAttrImmeuble(row) {
      this.modals.edit_attributs.show = true;
      this.modals.edit_attributs.immeuble_id = this.$route.params.id;
      this.modals.edit_attributs.id = row.id;
      this.modals.edit_attributs.name = row.name;
      this.modals.edit_attributs.attr_type = row.attr_type;
      console.log(row);
      switch (row.attr_type) {
        case "texte":
          this.modals.edit_attributs.value = JSON.parse(row.value);
          break;
        case "codes":
          this.modals.edit_attributs.codes = JSON.parse(row.value);
          break;
        case "fichiers":
          this.modals.edit_attributs.fichiers = row.fichiers;
          break;
        case "number":
          this.modals.edit_attributs.number = row.value;
          break;
        case "bool":
          this.modals.edit_attributs.bool = row.v
      }
      this.modals.edit_attributs.name = row.name;
    },
    async handleEditRole() {
      try {
        await this.$store.dispatch("role/editRole", this.item);
        await this.$store.dispatch("role/rolesList", {
          ...(this.options.sort ? { sort: this.options.sort } : {}),
          filter: {
            ...(this.options.query ? { name: this.options.query } : {}),
          },
          page: {
            number: this.options.nr,
            size: this.options.perpage,
          },
        });
        this.resetApiValidation();
        this.showSwal({
          type: "success",
          message: "Role edited successfully!"
        });
        await this.$router.push({ name: 'Role Management' });
      }
      catch (error) {
        this.setApiValidation(error.response.data.errors);
        this.showSwal({
          type: "error",
          message: "Oops, something went wrong!"
        });
      }
    },
  }
};
</script>
<style>
.el-tree-node__content {
  height: 100%!important;
}
</style>
