/* eslint-disable no-unused-vars */
import courrierservice from "../services/courrier.service.js";

export const courrier = {
    namespaced: true,
    state: {
        courriersList: null,
        courriersListFromBuilding: null,
        onecourrier: null,
        courriers_prices: null,
        image: null,
        phone: null,
    },
    getters: {
        courriersList: state => state.courriersList,
        courriers_prices: state => state.courriers_prices,
        courriersListFromBuilding: state => state.courriersListFromBuilding,
        onecourrier: state => state.onecourrier,
        image: state => state.image,
        phone: state => state.phone,
    },
    actions: {
        async courriersList({ commit }, params) {
            console.log("action")
            const data = await courrierservice.getListcourriers(params);
            console.log(data);
            commit('COURRIERS_LIST', data);
        },

        async courriersFromBuilding({ commit }, params) {
            console.log("action")
            const data = await courrierservice.getListcourriersFromBuilding(params);
            console.log(data);
            commit('COURRIERS_LIST_FROM_BUILDING', data);
        },
        async courriers_prices({commit, dispatch}, params) {
            return await courrierservice.courriers_prices(params)
            .then((list) => {
            commit('SET_courriers_prices', list);
            });
        },
        async getcourrier({ commit }, itemId) {
            const data = await courrierservice.getcourrier(itemId);
            commit('GET_COURRIER', data);
        },
        async createcourrier({ commit }, data) {
            const response = await courrierservice.createcourrier(data);
            commit('GET_COURRIER', response.data);
        },
    },
    mutations: {
        COURRIERS_LIST(state, data) {
            state.courriersList = data;
        },
        GET_COURRIER(state, data) {
            state.onecourrier = data;
        },
        LOTS_LIST_FROM_BUILDING(state, data) {
            state.lotsFromBuilding = data;
        },
        UPLOAD_IMAGE_LOT(state, data) {
            state.image = data;
        },
        SET_courriers_prices(state, data) {
            state.courriers_prices = data;
        },
    }
}
