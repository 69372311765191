import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListcourriers(params){
    const response =  await axios.get(`${API_URL}/courriers`, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, { encode: false });
        },
        headers: authHeader()
    });

    return {data: response.data.data, meta: response.data.meta};
}

async function getListcourriersFromBuilding(params){
    let immeuble_id = params.immeuble_id;
    const response =  await axios.get(`${API_URL}/immeubles/${immeuble_id}/baux`, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, { encode: false });
        },
        headers: authHeader()
    });

    return {data: response.data, meta: response.data.meta};
}

async function getcourrier(courrierId){
    const response =  await axios.get(`${API_URL}/courriers/${courrierId}`, {headers: authHeader()});
    return response.data;
}


function courriers_prices(params) {
    console.log(params);

    const response =   axios.get(`${API_URL}/courriers/prices`, {headers: authHeader()}).then(response => {
        return response.data;
    });
        console.log("COURRIER",response);
    return response;
}

async function editcourrier(courrier){

    const payload = jsona.serialize({
        stuff: courrier,
        includeNames: []
    });

    const response =  await axios.patch(`${API_URL}/courriers/${courrier.id}?include=category,tags`, payload, {headers: authHeader()});
    return jsona.deserialize(response.data);
}

async function createcourrier(courrier){


    const response =  await axios.post(`${API_URL}/courriers`, courrier, {headers: authHeader()});
    return response.data;
}

async function deletecourrier(courrierId){
    await axios.delete(`${API_URL}/courriers/${courrierId}`, {headers: authHeader()});
}

async function uploadImagecourrier(courrier) {
        const payload = new FormData();
        payload.append("attachment", courrier.image);

        const response = await axios.post(`${API_URL}/uploads/courriers/${courrier.id}/image`, payload, { headers: authHeader() });
        return response.data.url;

    }

export default {
    getListcourriers,getListcourriersFromBuilding,
        getcourrier,
        editcourrier,
        createcourrier,
        deletecourrier,
        uploadImagecourrier,
        courriers_prices
};
