<template>
    <Transition name="slide-up" mode="out-in">
  <div class="card  p-3" v-if="item.destinataire">

    <h3 class="h4">{{ item.objet}}</h3>
    <div>
        <button class="btn btn-xs btn-danger" v-if="item.immeuble" @click="goImm(item.immeuble?.id)">{{item.immeuble?.nom}}</button>
        <div class="btn btn-xs bg-default text-white">{{ new Date(item.created_at).toLocaleDateString("fr") }}</div>
        <button class="btn btn-xs bg-yellow" v-if="item.perso" @click="goPerso(item.perso?.id)">{{item.perso?.nom }} {{item.perso?.prenom }}</button>


    </div>


    <div v-if="item.type">
        {{ matching[item.type] || item.type }}
    </div>

    <div>
        {{ matchingService[item.service] }}
    </div>


    <textarea v-model="item.destinataire" type="text" class="form-control" placeholder="Destinataire" disabled style="height: 100px;"></textarea>



  </div>
</Transition>
</template>
<script>

export default {
  components: {
  },
  props: ["id"],
  data() {
    return {
      item: {
        ticket_reception:{
            title: '',
            description: ''
        }
      },
      matching: {
        'courrier_simple': 'Courrier Simple',
        'courrier_recommande': 'Courrier Recommandé AR-1',
        'colissimo': 'Colissimo',
        'courrier_international': 'Courrier International',

      },
      matchingService: {
        'copropriete': 'Copropriété',
        'gerance': 'Gérance locative',
        'transaction': 'Transaction',
        'imm_entreprise': 'Immobilier d\'entreprise',
        'generaux': 'Directions'
      },
      contexte: [],
      modals: {
        corrigerContexte: {
          show: false,
        },
      },
    };
  },
  watch: {
    id: {
      async handler() {
        this.get();
      },
    },
  },
  mounted() {
    //this.item = this.$route.params.item;
    this.get();
  },
  methods: {
    goImm(id) {
      this.$router.push({
        name: "Immeuble",
        params: {
          id: id,
        },
      });
    },
    goPerso(id) {
      this.$router.push({
        name: "Perso",
        params: {
          id: id,
        },
      });
    },
    goEvent(id) {
      this.$router.push({
        name: "Immeuble",
        params: {
          id: id,
        },
      });
    },
    async get() {
      try {
        console.log('QUERY', this.id)

        const id = this.id;
        console.log("QUERY", id, this.$route.query);
        this.mainid = id;
        if (!id) {
          return;
        }
        await this.$store.dispatch("courrier/getcourrier", id);
        var b = this.$store.getters["courrier/onecourrier"];
        if(b.ticket?.attributes && typeof b.ticket.attributes === 'string'){
            b.ticket.attributes = JSON.parse(b.ticket.attributes);
        }
        console.log("GET", b);
        this.item = b;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Problème de récupération de l'e-mail!`,
        });
      }
    },
  },
};
</script>
