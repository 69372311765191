import { createRouter, createWebHistory } from "vue-router";
import Landing from "../views/dashboards/Landing.vue";
import Default from "../views/dashboards/Default.vue";
import Automotive from "../views/dashboards/Automotive.vue";
import SmartHome from "../views/dashboards/SmartHome.vue";
import CRM from "../views/dashboards/CRM.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Teams from "../views/pages/profile/Teams.vue";
import Projects from "../views/pages/profile/Projects.vue";
import General from "../views/pages/projects/General.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import NewProject from "../views/pages/projects/NewProject.vue";
import Pricing from "../views/pages/Pricing.vue";
import Charts from "../views/pages/Charts.vue";
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import Analytics from "../views/applications/analytics/Analytics.vue";
import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/EditProduct.vue";
import ProductPage from "../views/ecommerce/ProductPage.vue";
import ProductsList from "../views/ecommerce/ProductsList.vue";
import OrderDetails from "../views/ecommerce/Orders/OrderDetails";
import OrderList from "../views/ecommerce/Orders/OrderList";
import Referral from "../views/ecommerce/Referral";
import Reports from "../views/pages/Users/Reports.vue";
import NewUser from "../views/pages/Users/NewUser.vue";
import Settings from "../views/pages/Account/Settings.vue";
import Billing from "../views/pages/Account/Billing.vue";
import Invoice from "../views/pages/Account/Invoice.vue";
import Security from "../views/pages/Account/Security.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetBasic from "../views/auth/reset/Basic.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import ResetIllustration from "../views/auth/reset/Illustration.vue";
import VerificationBasic from "../views/auth/verification/Basic.vue";
import VerificationCover from "../views/auth/verification/Cover.vue";
import VerificationIllustration from "../views/auth/verification/Illustration.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import SignupIllustration from "../views/auth/signup/Illustration.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import lockBasic from "../views/auth/lock/Basic.vue";
import lockCover from "../views/auth/lock/Cover.vue";
import lockIllustration from "../views/auth/lock/Illustration.vue";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import auth from "../middleware/auth";
import collaborateur from "../middleware/collaborateur";
import fournisseur from "../middleware/fournisseur";
import guest from "../middleware/guest";
import admin from "../middleware/admin";
//import admin_creator from "../middleware/admin_creator";
import WelcomePage from "../views/WelcomePage.vue";
import ListeImmeubles from "../views/collaborateur/immeubles/ListeImmeubles.vue";
import Immeuble from "../views/collaborateur/immeubles/Immeuble.vue";
import Resident from "../views/collaborateur/residents/Resident.vue";
import Lot from "../views/collaborateur/lots/Lot.vue";
import Bail from "../views/collaborateur/baux/Bail.vue";
import ListeMails from "../views/collaborateur/mails/ListeMails.vue";
import ListeResidents from "../views/collaborateur/residents/ListeResidents.vue";
import NewBien from "../views/collaborateur/biens/NewBien.vue";
import ListeAppels from "../views/collaborateur/appels/ListeAppels.vue";
import ListeFeedbacks from "../views/collaborateur/feedbacks/ListeFeedbacks.vue";
//import ListeCourriers from "../views/collaborateur/courriers/ListeCourriers.vue";
//import ListeDiscussions from "../views/collaborateur/discussions/ListeDiscussions.vue";
import ListeEvenements from "../views/collaborateur/evenements/ListeEvenements.vue";
import ListeFournisseurs from "../views/collaborateur/fournisseurs/ListeFournisseurs.vue";
import Fournisseur from "../views/collaborateur/fournisseurs/Fournisseur.vue";
import Event from "../views/collaborateur/evenements/Event.vue";
import EventFournisseur from "../views/fournisseur/evenements/Event.vue";

import ListeModelesAdmin from "../views/admin/modeles/ListeModeles.vue";
import NewModeleAdmin from "../views/admin/modeles/NewModele.vue";
import EditionModeleAdmin from "../views/admin/modeles/EditionModele.vue";

import ListeCollaborateurs from "../views/admin/collaborateurs/ListeCollaborateurs.vue";
import NewCollaborateur from "../views/admin/collaborateurs/NewCollaborateur.vue";
import EditionCollaborateur from "../views/admin/collaborateurs/EditionCollaborateur.vue";

import ListeControleurs from "../views/admin/controleurs/ListeControleurs.vue";
import EditionControleurAdmin from "../views/admin/controleurs/EditionControleur.vue";

import ListeVues from "../views/admin/vues/ListeVues.vue";
import NewVue from "../views/admin/vues/NewVue.vue";
import EditionVue from "../views/admin/vues/EditionVue.vue";

import ListeSuperSyndics from "../views/admin/supersyndics/ListeSuperSyndics.vue";
import SuperSyndic from "../views/admin/supersyndics/SuperSyndic.vue";
import ListeAgences from "../views/admin/agences/ListeAgences.vue";
import Agence from "../views/admin/agences/Agence.vue";
import AjoutFournisseur from "../views/collaborateur/fournisseurs/AjoutFournisseur.vue";

import Intranet from "../views/collaborateur/intranet/Intranet.vue";
//import Fichiers from "../views/collaborateur/intranet/Fichiers.vue";
import Root from "../views/collaborateur/intranet/Root.vue";
import Folder from "../views/collaborateur/intranet/Folder.vue";

import EnConstruction from "../views/EnConstruction.vue";
import ConnexionValide from "../views/ConnexionValide.vue";
import LoginCompany from "../views/auth/LoginCompany.vue";
import RegisterCompany from "../views/auth/RegisterCompany.vue";

import OutlookPage from "../views/collaborateur/OutlookPage.vue";
import ListeImmeublesCarte from "../views/collaborateur/immeubles/ListeImmeublesCarte.vue";
import Organigramme from "../views/collaborateur/collegues/Organigramme.vue";
import ListeCourriers from "../views/collaborateur/courriers/ListeCourriers.vue";

import Calendrier from "../views/collaborateur/calendrier/Calendrier.vue";
import ListeBaux from "../views/collaborateur/baux/ListeBaux.vue";

const routes = [
  {
    path: "/",
    name: "/",
    component: WelcomePage,
  },
  {
    path: "/microsoft/callback",
    name: "Microsoft",
    component: OutlookPage,
    beforeEnter: auth,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Default,
    beforeEnter: auth,
  },
  {
    path: "/enconstruction",
    name: "En construction",
    component: EnConstruction,
  },
  {
    path: "/connexionvalide",
    name: "Connexion valide",
    component: ConnexionValide,
  },
  {
    path: "/s/immeubles",
    name: "Liste des immeubles",
    component: ListeImmeubles,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/immeubles-carte",
    name: "Carte des immeubles",
    component: ListeImmeublesCarte,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/immeubles/:id",
    name: "Immeuble",
    component: Immeuble,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/organigramme",
    name: "Organigramme",
    component: Organigramme,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/residents",
    name: "Liste des résidents",
    component: ListeResidents,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/persos/:id",
    name: "Perso",
    component: Resident,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/lots/:id",
    name: "Lot",
    component: Lot,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/baux/",
    name: "Liste des baux",
    component: ListeBaux,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/baux/:id",
    name: "Bail",
    component: Bail,
    beforeEnter: [auth, collaborateur],
  },

  {
    path: "/s/calendrier",
    name: "Calendrier",
    component: Calendrier,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/mails",
    name: "Liste des mails",
    component: ListeMails,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/courriers",
    name: "Liste des courriers",
    component: ListeCourriers,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/calls",
    name: "Liste des appels",
    component: ListeAppels,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/feedbacks",
    name: "Liste des feedbacks",
    component: ListeFeedbacks,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/discussions",
    name: "Liste des discussions",
    component: EnConstruction,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/evenements",
    name: "Liste des évènements",
    component: ListeEvenements,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/evenements/:id",
    name: "Évènement",
    component: Event,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/f/evenements/:id",
    name: "ÉvènementF",
    component: EventFournisseur,
    beforeEnter: [fournisseur],
  },

  {
    path: "/s/assemblees",
    name: "Liste des assemblées",
    component: EnConstruction,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/dropzone",
    name: "Liste des dropzones",
    component: EnConstruction,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/intranet",
    name: "Liste Intranet",
    component: Intranet,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/intranet/:id",
    name: "Racine",
    component: Root,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/intranet/:id/folders/:folderId",
    name: "Dossier",
    component: Folder,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/s/webex/vue",
    name: "WebexVue",
    component: () => import("../views/collaborateur/appels/WebexView.vue"),
    beforeEnter: [auth, collaborateur],

  },
  {
    path: "/s/biens/new",
    name: "Nouveau bien",
    component: NewBien,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/supersyndics",
    name: "Liste des supersyndics",
    component: ListeSuperSyndics,
    beforeEnter: [auth, admin],
  },
  {
    path: "/supersyndics/:id",
    name: "SuperSyndic",
    component: SuperSyndic,
    beforeEnter: [auth, admin],
  },
  {
    path: "/agences",
    name: "Liste des agences",
    component: ListeAgences,
    beforeEnter: [auth, admin],
  },
  {
    path: "/agences/:id",
    name: "Agence",
    component: Agence,
    beforeEnter: [auth, admin],
  },
  {
    path: "/collaborateurs",
    name: "Liste des collaborateurs",
    component: ListeCollaborateurs,
    beforeEnter: [auth, admin],
  },
  {
    path: "/collaborateurs/new",
    name: "Nouveau collaborateur",
    component: NewCollaborateur,
    beforeEnter: [auth, admin],
  },
  {
    path: "/collaborateurs/:id",
    name: "Collaborateur",
    component: EditionCollaborateur,
    beforeEnter: [auth, admin],
  },
  {
    path: "/modeles",
    name: "Liste des modèles",
    component: ListeModelesAdmin,
    beforeEnter: [auth, admin],
  },
  {
    path: "/controleurs",
    name: "Liste des controleurs",
    component: ListeControleurs,
    beforeEnter: [auth, admin],
  },
  {
    path: "/vues",
    name: "Liste des vues",
    component: ListeVues,
    beforeEnter: [auth, admin],
  },
  {
    path: "/modeles/new",
    name: "Nouveau modèle",
    component: NewModeleAdmin,
    beforeEnter: [auth, admin],
  },
  {
    path: "/vues/new",
    name: "Nouvelle vue",
    component: NewVue,
    beforeEnter: [auth, admin],
  },
  {
    path: "/controleurs/new",
    name: "Nouveau controleur",
    component: NewModeleAdmin,
    beforeEnter: [auth, admin],
  },
  {
    path: "/modeles/:id",
    name: "Modèle",
    component: EditionModeleAdmin,
    beforeEnter: [auth, admin],
  },
  {
    path: "/vues/:id",
    name: "Vue",
    component: EditionVue,
    beforeEnter: [auth, admin],
  },
  {
    path: "/controleurs/:id",
    name: "Controleur",
    component: EditionControleurAdmin,
    beforeEnter: [auth, admin],
  },
  {
    path: "/fournisseurs",
    name: "Liste des fournisseurs",
    component: ListeFournisseurs,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/fournisseurs/add",
    name: "Ajouter un fournisseur",
    component: AjoutFournisseur,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/fournisseurs/:id",
    name: "Fournisseur",
    component: Fournisseur,
    beforeEnter: [auth, collaborateur],
  },
  {
    path: "/dashboards/landing",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/dashboards/automotive",
    name: "Automotive",
    component: Automotive,
  },
  {
    path: "/dashboards/smart-home",
    name: "Smart Home",
    component: SmartHome,
  },
  {
    path: "/dashboards/crm",
    name: "CRM",
    component: CRM,
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview,
  },
  {
    path: "/pages/profile/teams",
    name: "Teams",
    component: Teams,
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects,
  },
  {
    path: "/pages/projects/general",
    name: "General",
    component: General,
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline,
  },
  {
    path: "/pages/projects/new-project",
    name: "New Project",
    component: NewProject,
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing,
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts,
  },
  {
    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets,
  },
  {
    path: "/pages/sweet-alerts",
    name: "Sweet Alerts",
    component: SweetAlerts,
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban,
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard,
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables,
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar,
  },
  {
    path: "/applications/analytics",
    name: "Analytics",
    component: Analytics,
  },
  {
    path: "/ecommerce/overview",
    name: "Overview",
    component: EcommerceOverview,
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct,
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct,
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage,
  },
  {
    path: "/ecommerce/products/products-list",
    name: "Products List",
    component: ProductsList,
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails,
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList,
  },
  {
    path: "/ecommerce/referral",
    name: "Referral",
    component: Referral,
  },
  {
    path: "/pages/users/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser,
  },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/pages/account/Security",
    name: "Security",
    component: Security,
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic,
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover,
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration,
  },
  {
    path: "/authentication/reset/basic",
    name: "Reset Basic",
    component: ResetBasic,
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover,
  },
  {
    path: "/authentication/reset/illustration",
    name: "Reset Illustration",
    component: ResetIllustration,
  },
  {
    path: "/authentication/lock/basic",
    name: "Lock Basic",
    component: lockBasic,
  },
  {
    path: "/authentication/lock/cover",
    name: "Lock Cover",
    component: lockCover,
  },
  {
    path: "/authentication/lock/illustration",
    name: "Lock Illustration",
    component: lockIllustration,
  },
  {
    path: "/authentication/verification/basic",
    name: "Verification Basic",
    component: VerificationBasic,
  },
  {
    path: "/authentication/verification/cover",
    name: "Verification Cover",
    component: VerificationCover,
  },
  {
    path: "/authentication/verification/illustration",
    name: "Verification Illustration",
    component: VerificationIllustration,
  },
  {
    path: "/authentication/signup/basic",
    name: "Signup Basic",
    component: SignupBasic,
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover,
  },
  {
    path: "/authentication/signup/illustration",
    name: "Signup Illustration",
    component: SignupIllustration,
  },
  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404,
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: guest,
    },
  },
  {
    path: "/f/login",
    name: "LoginCompany",
    component: LoginCompany,
    meta: {
      middleware: guest,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      middleware: guest,
    },
  },
  {
    path: "/f/register",
    name: "RegisterCompany",
    component: RegisterCompany,
    meta: {
      middleware: guest,
    },
  },

  {
    path: "/password/forgot",
    name: "Forgot Password",
    component: ForgotPassword,
    meta: {
      middleware: guest,
    },
  },
  {
    path: "/password/reset",
    name: "Reset Password",
    component: ResetPassword,
    meta: {
      middleware: guest,
    },
  },
  // Cette route est la pour récupérer le code renvoyé par webex après l'authentification
  {
    path: "/webex",
    name: "WebexAuthentification",
    meta: {
      hideDefaultLayout: true,
    },
    component: () => import("../views/auth/WebexAuthentification.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});

export default router;
